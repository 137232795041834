import itemStoreModule from '@/store/items/item'
import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useItemView(itemId) {
  const vm = getCurrentInstance().proxy

  const id = ref(itemId)

  const MODULE_CONTAINER = 'items'
  const STORE_MODULE = computed(() => `${MODULE_CONTAINER}/${id.value}`)

  const item = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/data`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/loading`],
  })

  // fetch data
  const fetchData = () => vm.$store.dispatch(`${STORE_MODULE.value}/fetchData`)

  // onInit
  const init = () => {
    if (!vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
      vm.$store.registerModule([MODULE_CONTAINER, id.value], itemStoreModule(itemId))
    }

    vm.$store.dispatch(`${STORE_MODULE.value}/init`, id.value)

    fetchData()
  }

  // onDestroy
  const destroy = () => {
    // if (vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
    //   vm.$store.unregisterModule([MODULE_CONTAINER, id.value])
    // }
  }

  // watch([], () => {
  // loading.value = true
  // fetchData()
  // })

  return {
    loading,
    item,

    init,
    destroy,
    fetchData,
  }
}
