<template>
  <div id="item-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <item-bio-panel
          v-if="item"
          :data="item"
        ></item-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="itemTab"
          show-arrows
          class="transparent-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="transparent-tabs-content"
          v-model="itemTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="item"
            value="overview"
          >
            <item-tab-overview :data="item"></item-tab-overview>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import { mdiAccountOutline } from '@mdi/js'
import ItemBioPanel from './item-bio-panel/ItemBioPanel.vue'
import ItemTabOverview from './item-tab-overview/ItemTabOverview.vue'

import useItemView from './useItemView'

export default {
  components: {
    ItemBioPanel,
    ItemTabOverview,
  },
  setup() {
    const itemTab = ref(null)
    const { route } = useRouter()

    const {
      loading,
      item,

      init,
      destroy,
    } = useItemView(route.value.params.id)

    init()
    onUnmounted(() => {
      destroy()
    })

    // watch(
    //   () => route.value.params.id,
    //   id => init(id),
    //   { immediate: true },
    // )

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
    ]

    return {
      tabs,
      itemTab,

      item,
      loading,
    }
  },
}
</script>
