export default itemId => ({
  namespaced: true,
  state: () => ({
    id: itemId,
    data: null,
    loading: true,
  }),
  getters: {
    id: state => state.id,
    data: state => state.data,
    loading: state => state.loading,
  },
  mutations: {
    INIT(state, payload) {
      state.id = payload
    },
    SET_DATA(state, payload) {
      state.data = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    init({ commit }, payload) {
      commit('INIT', payload)
    },

    fetchData({ commit }) {
      commit('SET_LOADING', true)

      const { _vm: vm } = this

      vm.$http
        .get(`/v1/items/${itemId}`)
        .then(res => {
          commit('SET_DATA', res.data?.data?.item)
        })
        .finally(() => commit('SET_LOADING', false))
    },
  },
})
