<template>
  <v-row class="user-bio-panel">
    <!-- item category profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <router-link
            v-if="data.picture"
            :to="data.picture"
            :href="data.picture"
            target="_blank"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <v-avatar
              rounded
              size="120"
              class="mb-4"
            >
              <v-img :src="data.picture"></v-img>
            </v-avatar>
          </router-link>
          <v-avatar
            v-else
            rounded
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            size="120"
          >
            <span
              class="font-weight-semibold text-5xl"
            >{{ avatarText(data.displayName) }}</span>
          </v-avatar>

          <span class="mb-2">{{ data.displayName | textEllipsis(90) }}</span>

          <v-chip
            v-if="data.itemCategoryCode"
            label
            small
            color="primary"
            class="v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize"
          >
            {{ data.itemCategoryCode | textEllipsis(55) }}
          </v-chip>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ data.inventory > 0 ? icons.mdiCheck : icons.mdiClose }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(data.inventory) }}
              </h3>
              <span>Inventory</span>
            </div>
          </div>

          <!-- <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(data.projectDone) }}
              </h3>
              <span>Project Done</span>
            </div>
          </div> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCheck, mdiMinus, mdiClose, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// import useItemCategoryView from '../useItemCategoryView'

// import CustomerBioEdit from './CustomerBioEdit.vue'

export default {
  components: {
    // CustomerBioEdit,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },

    // isPlanUpgradeDialogVisible: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  setup() {
    // const { resolveCustomerStatusVariant, resolveCustomerRoleVariant } = useItemCategoryView()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      // resolveCustomerStatusVariant,
      // resolveCustomerRoleVariant,

      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiMinus,
        mdiClose,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
