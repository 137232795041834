import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"user-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[(_vm.data.picture)?_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":_vm.data.picture,"href":_vm.data.picture,"target":"_blank"}},[_c(VAvatar,{staticClass:"mb-4",attrs:{"rounded":"","size":"120"}},[_c(VImg,{attrs:{"src":_vm.data.picture}})],1)],1):_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text mb-4",attrs:{"rounded":"","color":"primary","size":"120"}},[_c('span',{staticClass:"font-weight-semibold text-5xl"},[_vm._v(_vm._s(_vm.avatarText(_vm.data.displayName)))])]),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm._f("textEllipsis")(_vm.data.displayName,90)))]),(_vm.data.itemCategoryCode)?_c(VChip,{staticClass:"v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(_vm.data.itemCategoryCode,55))+" ")]):_vm._e()],1),_c(VCardText,{staticClass:"d-flex justify-center flex-wrap mt-2 pe-sm-0"},[_c('div',{staticClass:"d-flex align-center me-8 mb-4"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" "+_vm._s(_vm.data.inventory > 0 ? _vm.icons.mdiCheck : _vm.icons.mdiClose)+" ")])],1),_c('div',[_c('h3',{staticClass:"text-xl font-weight-medium mb-n1"},[_vm._v(" "+_vm._s(_vm.kFormatter(_vm.data.inventory))+" ")]),_c('span',[_vm._v("Inventory")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }